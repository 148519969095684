import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { typographyStyles, Body, colours, breakpoints } from "design-kit"

import Footer from "../Shared/Footer"
import SharedHero from "../Shared/Hero"
import ImageSection from "../Shared/ImageSection"
import Navbar from "../Shared/Navbar"
import {
  HeroHeading,
  Main,
  Paragraph,
  Props,
  SectionHeading,
  colors,
} from "../Shared"

import allHandsImg from "./assets/all-hands.jpg"
import creativeImg from "./assets/creative.jpg"
import customerDrivenImg from "./assets/customer-driven.jpg"
import heroImg from "./assets/hero.jpg"
import supportedImg from "./assets/supported.jpg"

const Hero = styled(SharedHero)`
  min-height: 224px;
  display: flex;
  flex-direction: column;
`

const Heading = styled(HeroHeading)`
  margin: auto;

  ${breakpoints.tablet`
    width: 80%;
    margin-bottom: 48px;
  `}

  ${breakpoints.desktop`
    width: 55%;
  `}
`

const BoxContainer = styled.div`
  width: 100%;

  ${breakpoints.tablet`
    margin: auto;
    width: 80%;
  `}

  ${breakpoints.desktop`
    width: 55%;
  `}
`

const DesktopBoxContainer = styled(BoxContainer)`
  display: none;

  ${breakpoints.tablet`
    display: block;
  `}
`

const MobileBoxContainer = styled(BoxContainer)`
  display: block;

  ${breakpoints.tablet`
    display: none;
  `}
`

const Box = styled.div`
  ${typographyStyles.body};
  max-width: 848px;
  padding: 24px;
  background-color: ${colors.aquamarine};
  box-sizing: border-box;

  ${breakpoints.tablet`
    padding: 32px 48px;
    border-radius: 4px;
  `}

  ${breakpoints.desktop`
    padding: 64px 96px;
  `}
`

const BoxParagraph = styled(Body)`
  color: ${colours.offBlack};
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const View: React.FunctionComponent<Props> = ({ location }) => (
  <React.Fragment>
    <Navbar location={location} />

    <Main>
      <Hero backgroundImg={heroImg}>
        <Heading>
          Free but focused: <br /> How we work
        </Heading>

        <DesktopBoxContainer>
          <Box>
            <BoxParagraph>
              We’re not going to be small forever – but we are planning to be
              fast, nimble, productive, excited about our work, and proud of our
              teams forever.
            </BoxParagraph>
            <BoxParagraph>
              We’ll do it by following four principles about the way we work.
              Unite those with a culture as strong as ours, and you become
              unstoppable.
            </BoxParagraph>
          </Box>
        </DesktopBoxContainer>
      </Hero>

      <MobileBoxContainer>
        <Box>
          <BoxParagraph>
            We’re not going to be small forever – but we are planning to be
            fast, nimble, productive, excited about our work, and proud of our
            teams forever.
          </BoxParagraph>
          <BoxParagraph>
            We’ll do it by following four principles about the way we work.
            Unite those with a culture as strong as ours, and you become
            unstoppable.
          </BoxParagraph>
        </Box>
      </MobileBoxContainer>

      <ImageSection
        variant="reverse"
        image={supportedImg}
        backgroundColor={colors.gray}
      >
        <SectionHeading>Remote working</SectionHeading>

        <Paragraph>
          Aligned with a shared mission and values, our remote first policy
          thrives on individuality within a unified framework. Our approach to
          remote work champions flexibility and innovation. Just as we recognise
          there’s more than one solution to a problem, our remote work ethos
          empowers every team member to contribute uniquely to our shared
          success. Join us in an environment where collaboration transcends
          physical boundaries, fostering creativity, and propelling us toward
          collective achievement.
        </Paragraph>
      </ImageSection>

      <ImageSection
        variant="normal"
        image={creativeImg}
        backgroundColor={colours.offBlack}
      >
        <SectionHeading
          css={css`
            color: ${colours.white};
          `}
        >
          Free to be creative
        </SectionHeading>

        <Paragraph
          css={css`
            color: ${colours.white};
          `}
        >
          You can’t be creative if you’re tied up in bureaucracy. We give our
          teams tangible, measurable goals, then trust them to do incredible
          work without tying them up in red tape. Our crews are autonomous:
          we’re all driven by a shared vision, but no-one tells us how to get
          there. We decide. We also make sure our teams are a mix of multiple
          perspectives – designers, engineers, product owners. That creates
          better solutions.
        </Paragraph>
      </ImageSection>

      <ImageSection
        variant="reverse"
        image={customerDrivenImg}
        backgroundColor={colors.yellow}
      >
        <SectionHeading>Customer-driven</SectionHeading>

        <Paragraph>
          The people making decisions should be the people closest to customers,
          with actual experience of what our customers go through. We make sure
          everyone at Habito has a chance to go through our journey the way
          customers would, and sit with our operations team to build empathy and
          gain insights into what our customers need. We test and check things
          with our customers to find the solution that works for them, rather
          than the one we think will work for them.
        </Paragraph>
      </ImageSection>

      <ImageSection
        variant="normal"
        image={allHandsImg}
        backgroundColor={colors.gray}
      >
        <SectionHeading>Aligned, not all the same</SectionHeading>

        <Paragraph>
          We all share a mission, vision, and values at Habito. We all look to
          the same objectives as a company. We have tangible, measurable goals
          to meet. But we each bring our own skills and ideas to the vision and
          draw on different approaches to make it happen. There’s more than one
          way to solve a problem. We don’t limit ourselves.
        </Paragraph>
      </ImageSection>
    </Main>

    <Footer />
  </React.Fragment>
)

export default View
